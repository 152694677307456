import React from 'react'
import ProjectTemplate from '../../components/Template/ProjectTemplate'
import Seo from '../../components/Partials/Seo'

export default function GojekReactNative() {
  return (
    <ProjectTemplate>
      <Seo
        title="Gojek React Native - Project"
        image="/images/gojek-react-native-cover.png"
      />
      <h1>Gojek React Native</h1>
      <div className="mb-4">
        <img className="w-full" src="/images/gojek-react-native-cover.png" alt="Dicka Ismaji Personal Website" />
      </div>
      <section id="about" className="pt-4">
        <h2 className="font-bold">About</h2>
        <p>This is my updated personal web, after a few day after I buy this domain in then the website is launched</p>
      </section>
      <section id="overview" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>According to Google Chrome Lighthouse, when i test website perfomance the result is below</p>
      </section>
      <section id="responsibility" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>How</p>
      </section>
      <section id="technology" className="pt-4">
        <h2 className="font-bold">Technology</h2>
        <p>Design tool: Figma</p>
      </section>
      <section id="conclusion" className="pt-4">
        <h2 className="font-bold">Conclusion</h2>
        <p>Build a website from stratch is not easy</p>
      </section>
    </ProjectTemplate>
  )
}